import React from 'react';

import { BaseComponent } from '../base';


export default class Commonmark extends BaseComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 680 120" xmlSpace="preserve" {...this.props} >
        <polygon points="116,0 56.8,0 116,48.6" />
        <polygon points="0.7,120 64.7,120 0.7,69.9" />
        <path d="M450.756 55.793l-8.824-2.206c-4.584-1.161-7.865-2.744-9.85-4.746c-1.982-1.999-2.977-4.727-2.977-8.181 c0-4.549 1.523-8.071 4.568-10.569c3.045-2.496 7.336-3.744 12.875-3.744c5.881 0 10.4 1.5 13.7 4.4 c3.248 2.9 4.9 7.1 5 12.44h7.031c-0.104-7.25-2.385-12.892-6.85-16.929c-4.465-4.034-10.664-6.053-18.596-6.053 c-7.49 0-13.475 1.846-17.955 5.54c-4.48 3.693-6.721 8.603-6.721 14.723c0 5.5 1.5 9.8 4.4 12.9 c2.957 3.1 8.3 5.6 16 7.567l7.184 1.796c7.047 1.8 11.8 3.8 14.1 6.104c2.361 2.3 3.5 5.5 3.5 9.8 c0 4.652-1.686 8.303-5.053 10.954c-3.369 2.649-7.996 3.975-13.879 3.975c-6.701 0-11.9-1.641-15.594-4.924 s-5.574-7.9-5.643-13.851h-6.926v0.512c0 7.9 2.4 13.9 7.3 18.136c4.855 4.2 11.8 6.3 20.8 6.3 c8.035 0 14.371-1.948 19.006-5.848c4.635-3.897 6.951-9.233 6.951-16.004c0-5.883-1.76-10.499-5.283-13.853 C465.598 60.8 459.5 58 450.8 55.8" />
        <path d="M494.566 27.527h-6.311v15.648h-7.438v5.13h7.438v39.755c0 4.2 0.7 7.1 1.9 8.6 s3.523 2.4 6.7 2.386c1.129 0 2.328-0.096 3.592-0.281c1.268-0.189 2.666-0.489 4.207-0.899l-0.051-5.027 c-1.744 0.377-3.027 0.625-3.85 0.745c-0.82 0.119-1.555 0.179-2.203 0.179c-1.508 0-2.549-0.375-3.131-1.13 c-0.582-0.751-0.871-2.29-0.871-4.616V48.305h10.105v-5.13h-10.105V27.527z" />
        <path d="M536.99 41.79c-7.934 0-14.082 2.505-18.443 7.514c-4.359 5.01-6.541 12.081-6.541 21.2 c0 9.1 2.2 16.1 6.5 21.11c4.361 5 10.5 7.5 18.4 7.465c8.002 0 14.189-2.496 18.57-7.491 c4.377-4.992 6.566-12.02 6.566-21.084c0-9.132-2.199-16.203-6.592-21.213C551.141 44.3 545 41.8 537 41.8 M550.686 87.6 c-3.215 4.001-7.779 6.001-13.695 6.001c-5.848 0-10.389-2.016-13.619-6.052c-3.234-4.035-4.85-9.713-4.85-17.032 c0-7.352 1.615-13.047 4.85-17.083c3.23-4.035 7.771-6.053 13.619-6.053c5.916 0 10.5 2 13.7 6 c3.217 4 4.8 9.7 4.8 17.109S553.902 83.6 550.7 87.6" />
        <path d="M586.008 44.508c-2.789 1.812-4.969 4.549-6.541 8.207v-9.541h-6.105v54.531h6.514V67.644 c0-6.395 1.359-11.2 4.078-14.414c2.719-3.215 6.797-4.823 12.234-4.823h0.822V41.79h-0.717 C592.221 41.8 588.8 42.7 586 44.5" />
        <path d="M675.166 46.227c-2.926-2.957-7.346-4.437-13.262-4.437c-3.898 0-7.346 0.915-10.338 2.7 s-5.395 4.489-7.207 7.977c-0.891-3.42-2.68-6.062-5.377-7.925c-2.699-1.864-6.08-2.796-10.143-2.796 c-3.791 0-7.162 0.897-10.113 2.693c-2.957 1.795-5.355 4.369-7.199 7.72v-9.029h-5.9v54.532h6.311V64.001 c0-4.925 1.477-8.907 4.428-11.954c2.955-3.042 6.82-4.566 11.6-4.566c3.893 0 6.8 1.1 8.7 3.3 c1.879 2.2 2.8 5.5 2.8 10.054v36.886h6.309V63.54c0-4.821 1.453-8.723 4.361-11.696c2.906-2.976 6.771-4.463 11.592-4.463 c4.07 0 7 1 8.8 2.976c1.775 2 2.7 5.3 2.7 9.85v37.5h6.412V59.692 C679.551 53.7 678.1 49.2 675.2 46.2" />
        <path d="M118.944 67.886c-2.638-4.731-7.001-9.521-13.085-14.393L41.482 0.8 c-4.365 0.046-7.895 0.271-10.581 0.677c-2.734 0.466-5.502 1.227-8.287 2.282c-7.151 2.9-12.714 7.208-16.671 12.9 C1.991 22.4 0 29 0 36.76c0 2.8 0.4 5.8 1.2 8.973c0.762 3.2 1.8 6.1 3.2 8.8 c1.728 3.2 4.6 7 8.7 11.351c4.111 4.5 9.7 9.6 16.7 15.438c7.149 6.1 14.6 11.9 22.2 17.6 c4.731 3.4 8.8 6.4 12.3 8.825c3.447 2.4 9.3 6.5 17.5 12.247c3.098-0.096 5.736-0.263 7.912-0.451 c1.932-0.193 3.863-0.546 5.789-1.013c1.36-0.283 3.006-0.828 4.933-1.642c7.011-2.849 12.514-7.222 16.523-13.117 c4.048-5.868 6.093-12.454 6.093-19.776C122.984 78.1 121.6 72.7 118.9 67.9" />
        <path d="M182.358 53.177c-7.319-1.88-12.124-3.735-14.416-5.566c-2.292-1.829-3.435-4.181-3.435-7.054 c0-2.838 1.297-5.215 3.896-7.13c2.599-1.915 5.952-2.873 10.056-2.873c4.958 0 8.9 1.2 11.9 3.6 c2.958 2.4 4.6 5.8 4.8 10.056l11.953-0.051c-0.07-7.352-2.559-13.192-7.466-17.519c-4.908-4.327-11.483-6.49-19.725-6.49 c-8.482 0-15.279 1.976-20.391 5.926c-5.112 3.95-7.669 9.073-7.669 15.363c0 5.9 1.7 10.6 5.2 14 c3.489 3.4 9.5 6.3 17.9 8.49c0.754 0.2 1.9 0.4 3.3 0.822c6.839 1.7 11.2 3.4 13.2 5.2 c1.197 1.1 2.2 2.4 2.9 3.924c0.77 1.5 1.2 2.9 1.2 4.079c0 3.044-1.375 5.609-4.128 7.7 c-2.754 2.087-6.285 3.131-10.595 3.131c-5.711 0-10.234-1.351-13.567-4.053c-3.336-2.702-5.174-6.498-5.514-11.389h-12.212 c0.378 8.5 3.2 15 8.4 19.494c5.214 4.5 12.5 6.8 21.9 6.771c8.893 0 15.997-2.163 21.315-6.49 c5.319-4.324 7.977-10.045 7.977-17.16c0-5.882-1.7-10.465-5.104-13.746C200.73 59 193.5 56 182.4 53.2" />
        <path d="M233.915 27.117h-11.646v15.236h-7.9v8.721h7.9v34.012c0 5 0.9 8.5 2.8 10.4 c1.899 1.9 5.2 2.9 9.8 2.924c1.366 0 3.726-0.171 7.078-0.513c0.511-0.035 0.905-0.068 1.18-0.103v-9.335l-2.308 0.1 c-0.239 0-0.609 0.018-1.105 0.051c-0.494 0.035-0.895 0.053-1.206 0.053c-1.777 0-2.99-0.333-3.639-1 c-0.651-0.668-0.976-2.01-0.976-4.027V51.074h9.234v-8.721h-9.234V27.117z" />
        <path d="M299.373 89.498c-1.267 0-2.147-0.325-2.643-0.977c-0.497-0.648-0.745-2.032-0.745-4.153V56.922 c0-5.404-1.871-9.481-5.617-12.234c-3.744-2.754-9.293-4.13-16.647-4.13c-7.011 0-12.541 1.599-16.594 4.8 c-4.053 3.2-6.081 7.551-6.081 13.058v0.923h11.134v-0.41c0-2.805 0.957-4.959 2.872-6.465c1.916-1.505 4.667-2.257 8.259-2.257 c3.865 0 6.7 0.6 8.6 1.897c1.845 1.3 2.8 3.2 2.8 5.9c0 3.283-3.711 5.404-11.133 6.4 c-0.513 0.069-0.908 0.12-1.18 0.153c-8.893 1.129-15.03 3.104-18.416 5.924c-3.386 2.822-5.079 7.038-5.079 12.6 c0 5.1 1.6 9.1 4.7 12.056c3.164 2.9 7.5 4.4 13.1 4.36c3.626 0 7.027-0.794 10.208-2.386 c3.18-1.591 5.899-3.838 8.158-6.746c0.068 2.8 1 4.9 2.7 6.311c1.726 1.4 4.4 2.1 7.9 2.1 c0.925 0 1.89-0.069 2.899-0.205c1.009-0.139 2.044-0.345 3.104-0.616v-8.567c-0.718 0.068-1.224 0.112-1.514 0.1 C299.876 89.5 299.6 89.5 299.4 89.5 M284.547 76.878c0 3.831-1.436 6.976-4.31 9.4 c-2.873 2.464-6.481 3.694-10.825 3.694c-2.873 0-5.095-0.692-6.668-2.079c-1.574-1.384-2.36-3.341-2.36-5.874 c0-2.256 0.667-4.077 2.001-5.463c1.333-1.384 3.368-2.333 6.105-2.847c0.922-0.171 2.36-0.41 4.31-0.717 c5.369-0.787 9.284-1.814 11.747-3.079V76.878z" />
        <path d="M341.311 86.086c-2.174 2-5.021 3.002-8.542 3.002c-4.411 0-7.78-1.634-10.105-4.9s-3.489-8.01-3.489-14.236 c0-6.359 1.174-11.173 3.515-14.439c2.342-3.267 5.805-4.901 10.389-4.901c3.419 0 6.2 1 8.3 2.8 c2.119 1.9 3.3 4.5 3.6 7.774h11.541c-0.34-6.43-2.643-11.483-6.9-15.161c-4.256-3.676-9.891-5.514-16.902-5.514 c-8.036 0-14.337 2.625-18.902 7.874c-4.566 5.25-6.849 12.492-6.849 21.726c0 9 2.2 16.2 6.6 21.4 c4.411 5.3 10.4 7.9 18 7.899c7.355 0 13.246-1.957 17.675-5.872c4.428-3.917 6.916-9.312 7.463-16.186h-11.389 C344.822 81.2 343.5 84.1 341.3 86.1" />
        <polygon points="414,42.4 399.7,42.4 377.9,64.9 377.9,22.1 366.4,22.1 366.4,97.7 377.9,97.7 377.9,79.2 385.8,71.2 400.7,97.7 414.4,97.7 394,62.7" />
      </svg>
    );
  }
}
